<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan" v-if="!currentUserInfo.department || currentUserInfo.department === 5">
            <a-form-item label="部门">
              <a-select showSearch allowClear style="width: 100%" v-model="queryParam.department" @change="$refs.table.refresh(true)">
                <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="申请日期">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款日期">
              <a-range-picker @change="onPayDateChange" v-model="claimDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="状态" prop="flag">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam.refund_status"
                placeholder="请选择状态"
              >
                <a-select-option v-for="op in refundStatusOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款人名称">
              <a-input v-model.trim="queryParam['customer_name']" placeholder="根据付款人名称搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="银行流水号">
              <a-input v-model.trim="queryParam.bankNumber" placeholder="根据银行流水号搜索" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" v-if="!currentUserInfo.department || currentUserInfo.department === 5" @click="downloadRefundCert('all')">下载凭证</a-button>
      <!-- <a-button type="primary" @click="handleAccountType" :disabled="selectedRowKeys.length <= 0">设置账款类型</a-button> -->
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="certInfo" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{ item.cert_num + '(' + transCertStatus(item.cert_status) + ')' }}</span>
      </div>
      <div slot="moneyRegisterSlot" slot-scope="item">
        <span style="display:block">银行流水：{{ item.bank_serial_number }}</span>
        <span style="display:block">交易日: {{ item.arrive_date && moment(item.arrive_date).format('YYYY/MM/DD') }}</span>
        <span style="display:block">付款人：{{ item.pay_name }}</span>
        <span style="display:block">来款金额/认领金额/退款金额：{{ item.amount }}/{{item.claim_amount}}/{{item.refund_amount}}</span>
        <span style="display:block">交易附言：{{ item.remark }}</span>
      </div>
      <div slot="businessInfoSlot" slot-scope="text, record">
        <span style="display:block">申请人：{{ record.creator }}</span>
        <span style="display:block">申请时间：{{ record.created_at && moment(record.created_at).format('YYYY/MM/DD') }}</span>
        <span style="display:block">{{ record.check_user_name ? '部门审核：'+record.check_user_name : '' }}</span>
      </div>
      <div slot="fmsCheckInfoSlot" slot-scope="text, record">
        <span style="display:block">{{ record.fms_check_user_name }}</span>
        <span style="display:block">{{ record.fms_check_time && moment(record.fms_check_time).format('YYYY/MM/DD') }}</span>
        <span style="display:block">{{ record.reject_remark }}</span>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a v-if="record.can_depart_check" @click="handleDepartCheck(record)">部门审核</a>
          <a v-if="record.can_fms_check" @click="handleFmsCheckRefund(record)">商务审核</a>
          <a v-if="record.refund_status === 'wait_refund' && !record.payment_date &&(!currentUserInfo.department || currentUserInfo.department === 5)" @click="showRefundCashier(record)">资金出纳</a>
          <a v-if="record.refund_status === 'refunded' && record.payment_date &&(!currentUserInfo.department || currentUserInfo.department === 5)" @click="handleDeleteFundCashier(record)" style="margin-left:10px">删除出纳</a>
          <a @click="preprintRefundBill(record)" style="margin-left:10px">付款单</a>
          <a v-if="record.refund_status === 'refunded' && (!currentUserInfo.department || currentUserInfo.department === 5)" @click="previewRefundCert(record)" style="margin-left:10px">预览凭证</a>
        </template>
      </span>
    </s-table>
    <a-modal
      :visible="cashierVisible"
      :confirmLoading="cashierLoading"
      title="资金出纳"
      @cancel="cashierVisible = false"
      @ok="handleReFundCashier"
    >
      <a-form-model layout="vertical" :model="cashierForm" :rules="cashierRules" ref="cashierForm">
        <a-row :gutter="30">
          <a-col :span="12">
            <a-form-model-item label="付款日期" prop="payment_date">
              <a-date-picker v-model="cashierForm.payment_date" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="18">
            <a-form-model-item label="付款银行" prop="payment_bank">
              <a-select
                showSearch
                ref="bank"
                :filter-option="filterOption"
                allowClear
                placeholder="请选择银行信息"
                style="width: 100%"
                v-model="cashierForm.payment_bank"
              >
                <a-select-option v-for="op in bankOps" :key="op.id">{{
                  op.bank + '/' + op.bank_account
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="refundCertDetailVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="refundCertDetailVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="付款凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane
            v-for="item in certData"
            :key="item.id"
            :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'"
          >
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateRefundCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadRefundCert">
          下载凭证
        </a-button>
        <!-- <a-button type="primary" @click="printCert"> 打印凭证 </a-button> -->
        <a-button @click="refundCertDetailVisible = false"> 关闭 </a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printRefundBillModel"
      :confirmLoading="printRefundBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printRefundBillModel = false"
    >
      <div id="printRefundBill">
        <fms-refund-bill
          :orderData="printRefundBillRecord"
        >
        </fms-refund-bill>
      </div>
      <template slot="footer">
        <a-button id="printRefundBillBtn" key="submit" type="primary" v-print="printRefundBill">确认打印 </a-button>
        <a-button type="primary" @click="printRefundBillModel = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getSystemPage } from '@/api/system'
import {
  getRefundApplyPage,
  getFmsMetaOption,
  businessCheckRefund,
  fmsCheckRefund,
  refundCashier,
  deleteRefundCashier,
  queryRefundCert,
  delCert,
  certConfirm,
  certUnConfirm,
  generateRefundCert,
  downloadRefundCert
} from '@/api/fms'
import { getCustomer, downloadExcel } from '@/api/common'
import { getFirstLetter } from '@/utils/util'
import { Empty } from 'ant-design-vue'
import fmsRefundBill from '@/views/fms/bill/FmsRefundBill'
export default {
  components: {
    STable,
    Empty,
    fmsRefundBill
  },
  data() {
    this.lastFetchId = 0
    return {
      bankItem: '',
      showTotal: '合计：0',
      formVisible: false,
      confirmLoading: false,
      currentUserInfo: this.$store.getters.userInfo,
      queryParam: {
        department: null
      },
      claimDate: [],
      userOps: [],
      customerOps: [],
      claimAmounts: [],
      bankOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: (text) => {
            if (text === 0) return '-'
            if (text === 100) return '混合部门'
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '退款客户',
          dataIndex: 'refund_customer_name'
        },
        {
          title: '退款金额',
          dataIndex: 'refund_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '退款原因',
          dataIndex: 'refund_reason'
        },
        {
          title: '状态',
          dataIndex: 'refund_status',
          customRender: text => {
            return this.refundStatusOps[text].name
          }
        },
        {
          title: '来款信息',
          dataIndex: 'money_register',
          scopedSlots: { customRender: 'moneyRegisterSlot' },
          width: 300
        },
        {
          title: '业务操作',
          dataIndex: 'check_user_name',
          scopedSlots: { customRender: 'businessInfoSlot' }
        },
        {
          title: '财务审核信息',
          dataIndex: 'fms_check_user_name',
          scopedSlots: { customRender: 'fmsCheckInfoSlot' }
        },
        {
          title: '打款时间',
          dataIndex: 'payment_date',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      defaultInvoiceDate: [],
      selectedRowKeys: [],
      businessCheckList: [],
      refundStatusOps: {
        'depart_check': { value: 'depart_check', name: '待部门审核' },
        'fms_check': { value: 'fms_check', name: '待商务审核' },
        'wait_refund': { value: 'wait_refund', name: '待退款' },
        'refunded': { value: 'refunded', name: '已退款' },
        'reject': { value: 'reject', name: '驳回' }
      },
      isAdvanceOps: [
        {
          value: 1,
          name: '非预收'
        },
        {
          value: 2,
          name: '预收'
        },
        {
          value: 0,
          name: '未设置'
        }
      ],
      loadData: parameter => {
        if (!this.currentUserInfo.department || this.currentUserInfo.department !== 5) {
          this.queryParam.department = this.currentUserInfo.department
        }
        return getRefundApplyPage(Object.assign(parameter, this.queryParam)).then(res => {
          return {
            list: res.records,
            total: res.total
          }
        })
      },
      fetching: false,
      payName: null,
      accountTypeVisible: false,
      accountType: 1,
      accountDepartment: undefined,
      endDealDate: undefined,
      payerCustomerOps: [],
      payerCustomerId: null,
      payerCustomerInfo: undefined,
      payerFetching: false,
      updatePayerId: null,
      // 资金出纳
      cashierVisible: false,
      cashierLoading: false,
      cashierForm: {
        refund_id: null, // 进行资金退款的记录id
        payment_date: null, // 付款日期
        payment_bank: null // 付款银行
      },
      cashierRules: {
        payment_date: [{ required: true, message: '请选择付款日期', trigger: 'blur' }],
        payment_bank: [{ required: true, message: '请选择付款银行', trigger: 'blur' }]
      },
      refundCertDetailVisible: false,
      currentCertId: null,
      currentRefundRecord: null,
      certData: [],
      certColumns: [
        {
          title: '摘要',
          dataIndex: 'summary',
          width: 300
        },
        {
          title: '业务编号/发票号',
          dataIndex: 'number',
          width: 120
        },
        {
          title: '一级科目',
          dataIndex: 'first_subject',
          width: 200
        },
        {
          title: '明细科目',
          dataIndex: 'detail_subject',
          width: 200
        },
        {
          title: '外币金额',
          dataIndex: 'amount',
          width: 100
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate',
          width: 100
        },
        {
          title: '金额',
          children: [
            {
              title: '借方',
              dataIndex: 'borrow',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            },
            {
              title: '贷方',
              dataIndex: 'loan',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            }
          ]
        }
      ],
      printRefundBillModel: false,
      printRefundBillModelLoading: false,
      printRefundBillRecord: {},
      printRefundBill: {
        id: 'printRefundBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      }
    }
  },
  created() {
    getSystemPage('user', {
      page: 1,
      page_size: 1000
    }).then(v => {
      this.userOps = v.list
    })
    getFmsMetaOption('fms_bank').then(v => {
      this.bankOps = v
    })
  },
  watch: {
    selectedOrder() {
      let total = 0
      for (const item of this.orderData) {
        if (this.selectedOrder.includes(item.id)) {
          total += item.can_claim_amount
        }
      }
      this.showTotal = `合计：${total}`
    }
  },
  methods: {
    moment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getPayerCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.payerCustomerOps = []
      this.payerFetching = true
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.payerCustomerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
          this.payerCustomerId = res[0].value
        } else {
          this.payerCustomerInfo = undefined
          this.payerCustomerId = null
        }
        this.payerCustomerOps = res
        this.payerFetching = false
      })
    },
    changePayerCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.payerCustomerInfo = undefined;
        this.payerCustomerId = null;
      } else {
        // 委托客户选择框中有值
        this.payerCustomerInfo = value;
        this.payerCustomerId = value.key;
      }
      this.payerCustomerOps = []
      this.payerFetching = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam.apply_begin_time = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.apply_end_time = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam.apply_begin_time = null
        this.queryParam.apply_end_time = null
      }
    },
    moneyFlagChange() {
      this.$refs.table.refresh(false)
    },
    isAdvanceFlagChange() {
      this.$refs.table.refresh(false)
    },
    handleAccountType() {
      this.accountTypeVisible = true
      this.accountType = 1
    },
    handleCloseAccountType() {
      this.accountTypeVisible = false
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    transCertStatus(status) {
      switch (status) {
        case 1:
          return '待确认'
        case 2:
          return '已确认'
        case 3:
          return '已下载'
        default:
          return ''
      }
    },
    onPayDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam.pay_begin_time = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.pay_end_time = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam.pay_begin_time = null
        this.queryParam.pay_end_time = null
      }
    },
    handleDepartCheck(record) {
      this.$confirm({
        title: '确认提示',
        content: '是否确认审核通过退款单？',
        onOk: () => {
          businessCheckRefund({ refund_id: record.id }).then(v => {
            this.$message.success('审核成功')
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    handleFmsCheckRefund(record) {
      this.$confirm({
        title: '确认提示',
        content: '是否确认审核通过退款单？',
        onOk: () => {
          fmsCheckRefund({ refund_id: record.id }).then(v => {
            this.$message.success('审核成功')
            this.$refs.table.refresh(false)
          })
        }
      })
    },
        // 资金出纳
    showRefundCashier(record) {
      this.cashierVisible = true
      this.cashierForm = {
        refund_id: record.id, // 进行资金出纳的记录id
        payment_date: record.payment_date, // 付款日期
        payment_bank: record.payment_bank // 付款银行
      }
    },
    handleReFundCashier() {
      this.$refs.cashierForm.validate(valid => {
        if (valid) {
          const data = {
            refund_id: this.cashierForm.refund_id,
            payment_date: moment(this.cashierForm.payment_date).valueOf(),
            payment_bank: this.cashierForm.payment_bank
          }
          refundCashier(data).then(_ => {
              this.$notification['success']({
                message: '提示',
                description: '操作成功.'
              })
              this.$refs.table.refresh(false)
              this.$refs.cashierForm.resetFields()
            })
            .finally(() => {
              this.cashierVisible = false
              this.cashierLoading = false
            })
        } else {
          return false
        }
      })
    },
    handleDeleteFundCashier(record) {
      this.$confirm({
        title: '确认提示',
        content: '是否确认删除出纳信息？',
        onOk: () => {
          deleteRefundCashier({ refund_id: record.id }).then(v => {
            this.$message.success('审核成功')
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    // 预览应付、预付凭证
    previewRefundCert(record) {
      this.currentRefundRecord = record
      queryRefundCert({ refund_id: record.id }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
        this.refundCertDetailVisible = true
      })
    },
    delCertInfo(type) {
      delCert({ id: this.currentCertId }).then(res => {
        if (res) {
          this.$message.success('删除凭证成功')
          this.$refs.table.refresh(false)
          this.previewRefundCert(this.currentRefundRecord);
        }
      })
    },
    generateRefundCert () {
      generateRefundCert({
        refund_id: this.currentRefundRecord.id
      }).then(res => {
        if (res) {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewRefundCert(this.currentRefundRecord)
        }
      })
    },
    handleConfirm(type) {
      certConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证确认成功')
          this.$refs.table.refresh(false)
          this.previewRefundCert(this.currentRefundRecord)
        }
      })
    },
    handleUnConfirm() {
      certUnConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证反确认成功')
          this.$refs.table.refresh(false)
          this.previewRefundCert(this.currentRefundRecord)
        }
      })
    },
    downloadRefundCert(flag) {
      let list = []
      if (flag === 'all') {
        list = this.selectedRowKeys
      } else {
        list.push(this.currentRefundRecord.id)
      }
      downloadRefundCert({ list }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.table.refresh(false)
            if (flag !== 'all') {
              this.previewRefundCert(this.currentRefundRecord)
            }
          })
        }
      })
    },
    preprintRefundBill(record) {
      this.printRefundBillModel = true
      this.printRefundBillRecord = record
    }
  }
}
</script>
