<template>
  <div id="print"><h1 style="text-align: center; font-weight: 400">上海奥吉实业有限公司</h1>
    <h3 style="text-align: center; font-weight: 800">付款通知单</h3>
    <div>
      <div style="display: flex;width: calc(100% - 40px);margin-bottom:15px;">
        <div style="flex:1">
          <span style="font-weight: 800;font-size: 16px">付款方式：转账</span>
        </div>
        付款单位:
        <!-- 其他部门展示 -->
        <span class="custom-style" style="width: 200px;margin-right:10px;">
          {{ this.GLOBAL.departmentMaps[data.department].name }}
        </span>
        <span>退款申请日期：</span>
        <span class="custom-style">{{ moment(this.data.created_at).format('YYYY') }}</span>年
        <span class="custom-style">{{ moment(this.data.created_at).format('MM') }}</span>月
        <span class="custom-style">{{ moment(this.data.created_at).format('DD') }}</span>日
      </div>
      <div style="display: flex;width: calc(100% - 40px);margin-bottom:15px;">
        <div style="flex:1">
          <span style="font-weight: 800;font-size: 16px">收款单位：{{ data.refund_customer_name }}</span>
        </div>
        <!-- <span>出纳时间：</span>
        <span class="custom-style">{{ this.data.payment_date?moment(this.data.payment_date).format('YYYY'): "" }}</span>年
        <span class="custom-style">{{ this.data.payment_date?moment(this.data.payment_date).format('MM'): "" }}</span>月
        <span class="custom-style">{{ this.data.payment_date?moment(this.data.payment_date).format('DD') : ""}}</span>日 -->
      </div>
      <div style="margin-bottom:15px;">
        <table>
          <tbody>
            <tr>
              <td colspan="4">
                <span class="weightFont">退款原因：</span>
                <span class="weightFont">{{ this.data.refund_reason }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <span class="weightFont">合计: </span>
                <template v-if="data.money_register.currency ==='CNY'">
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                </template>
                <template v-else>
                  <span class="weightFont">{{ this.orderData.money_register.currency }} </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                </template>
              </td>
              <td class="weightFont" style="text-align: right">{{ this.orderData.money_register.currency }} {{ moneyFormatter(this.money) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p id="footer">
      <span>总经理：</span>
      <span>财务经理：</span>
      <span>分管领导：</span>
      <span>部门经理：{{ data.check_user_name }}</span>
      <span>商务审核：{{ data.fms_check_user_name }}</span>
      <span>经办人：{{ userInfo.name }}</span>
    </p>
  </div>
</template>

<script>
import moment from 'moment'
import { numberToChinese } from '@/utils/util'
export default {
  name: 'FmsPayBill',
  components: {},
  props: {
    orderData: {
      type: Object,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    orderData(val, oldVal) {
      this.data = val
    }
  },
  data() {
    return {
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      data: {},
      userInfo: this.$store.getters.userInfo,
      year: moment().year(),
      month: moment().month() + 1,
      date: moment().date(),
      money: '',
      cnMoney: ''
    }
  },
  methods: {
    numberToChinese,
    moment,
    initData() {
      this.data = this.orderData
      this.cnMoney = this.orderData.refund_amount.toFixed(2) + ''
      this.money = this.orderData.refund_amount
    },
    getCurrency(s) {
      if (s === undefined || '') {
        return 'hidden'
      } else {
        return 'show'
      }
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    getFeeDigit(s) {
      if (s) {
        return s
      } else {
        return ' '
      }
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.weightFont{
  font-weight: 1000;font-size: 16px
}
.title{
  width: 100%;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 80%; margin: 0 auto; padding-top: 50px}
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc; }
/*th:first-of-type { width:80% }*/
/*th:last-of-type { width:20% }*/
#footer span { display: inline-block; width: 16% }
.custom-style{display: inline-block;width: 50px;border-bottom: 1px solid #cccccc;text-align: center;}
.show{display: inline;}
.hidden{display: none;}
</style>
